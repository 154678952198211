var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"wrap"},[_c('div',{style:({
        width:_vm.wrapWidth + 'px',
        height:_vm.wrapHieght + 'px',
        'background-image':("url(" + _vm.url + ")"),
        'flex-shrink':0
       }),attrs:{"id":"do_card_wrap"},on:{"mousedown":_vm.draggableFun,"mousemove":_vm.mousemove,"mouseup":_vm.up}},_vm._l((_vm.lists),function(item,index){return _c('div',{key:index,staticClass:"line",style:({
      top:item.points.y+'px',
      left:item.points.x+'px',
      width:item.points.w+'px',
      height:item.points.h+'px',
      background:item.color,
    })},[(item.points.w)?_c('span',[_vm._v(" "+_vm._s(item.label)+" ")]):_vm._e()])}),0),_c('div',{staticClass:"list"},[_vm._l((_vm.lists),function(item,index){return _c('div',{key:index,staticClass:"item",style:({color:index == _vm.activeIndex?'#409EFF':''}),on:{"click":function($event){return _vm.checkItem(index)}}},[_c('span',[(index == 0)?_c('span',{staticStyle:{"width":"100px","display":"inline-block"}},[_vm._v("准考证")]):(index == 1)?_c('span',{staticStyle:{"width":"100px","display":"inline-block"}},[_vm._v("考试id")]):(index == 2)?_c('span',{staticStyle:{"width":"100px","display":"inline-block"}},[_vm._v("用户id")]):(index == 3 && _vm.num == 1)?_c('span',{staticStyle:{"width":"100px","display":"inline-block"}},[_vm._v("选择题区域")]):_c('span',{staticStyle:{"width":"100px","display":"inline-block"}},[_vm._v("标记")]),_vm._v(": "),_c('el-input',{staticStyle:{"width":"100px"},model:{value:(item.label),callback:function ($$v) {_vm.$set(item, "label", $$v)},expression:"item.label"}})],1),_c('span',[_vm._v("x:"+_vm._s(item.points.x))]),_c('span',[_vm._v("y:"+_vm._s(item.points.y))]),_c('span',[_vm._v("w:"+_vm._s(item.points.w))]),_c('span',[_vm._v("h:"+_vm._s(item.points.h))]),((_vm.num == 1 && index > 3) || (_vm.num == 2 && index>2))?_c('i',{staticClass:"el-icon-delete",staticStyle:{"color":"red"},on:{"click":function($event){return _vm.del(index)}}}):_vm._e()])}),_c('div',[_c('img',{staticClass:"add",attrs:{"src":require("@/assets/add.png"),"alt":""},on:{"click":_vm.add}})]),_c('el-button',{attrs:{"type":"primary"},on:{"click":_vm.submit}},[_vm._v(" 确定 ")])],2),_c('img',{attrs:{"src":_vm.url,"id":"card_img","alt":""}})])}
var staticRenderFns = []

export { render, staticRenderFns }