<template>
  <div class="wrap">
    <div id="do_card_wrap"
         @mousedown="draggableFun"
         @mousemove="mousemove"
         @mouseup="up"
         :style="{
          width:wrapWidth + 'px',
          height:wrapHieght + 'px',
          'background-image':`url(${url})`,
          'flex-shrink':0
         }">
      <div class="line"
           v-for="(item,index) in lists"
           :key="index"
           :style="{
        top:item.points.y+'px',
        left:item.points.x+'px',
        width:item.points.w+'px',
        height:item.points.h+'px',
        background:item.color,
      }">
        <span v-if="item.points.w">
          {{item.label}}
        </span>
      </div>
    </div>
    <div class="list">
      <div v-for="(item,index) in lists"
           @click="checkItem(index)"
           :style="{color:index == activeIndex?'#409EFF':''}"
           class="item"
           :key="index">
        <!-- 0:准考证  2考试id 3用户id  4选择题区域-->
        <span><span style="width:100px;display:inline-block"
                v-if="index == 0">准考证</span>
          <span style="width:100px;display:inline-block"
                v-else-if="index == 1">考试id</span>
          <span style="width:100px;display:inline-block"
                v-else-if="index == 2">用户id</span>
          <span style="width:100px;display:inline-block"
                v-else-if="index == 3 && num == 1">选择题区域</span>
          <span style="width:100px;display:inline-block"
                v-else>标记</span>:
          <el-input v-model="item.label"
                    style="width:100px" />
        </span>
        <span>x:{{item.points.x}}</span>
        <span>y:{{item.points.y}}</span>
        <span>w:{{item.points.w}}</span>
        <span>h:{{item.points.h}}</span>
        <!-- <span>color:{{item.color}}</span> -->
        <i v-if="(num == 1 && index > 3) || (num == 2 && index>2)"
           style="color:red"
           @click="del(index)"
           class="el-icon-delete"></i>
      </div>

      <div>
        <img src="@/assets/add.png"
             @click="add"
             class="add"
             alt="">
        <!-- <el-button type="primary"
                 >
        添加
      </el-button> -->

      </div>
      <el-button type="primary"
                 @click="submit">
        确定
      </el-button>
    </div>

    <img :src="url"
         id="card_img"
         alt="">
  </div>
</template>

<script>
import html2canvas from 'html2canvas'
import { upload } from '@/api/upload.js'
import getMd5Form from '@/utils/getMd5Form.js'
export default {
  data () {
    return {
      activeIndex: 0,
      active: false,
      top: 0,
      left: 0,
      width: 0,
      height: 0,
      lists: [],
      wrapWidth: 0,
      wrapHieght: 0,
      url: '',
      // 记录当前正反面,1正面2反面
      num: '',
      // 当前激活项
      activeIndex: 0
    }

  },
  mounted () {
  },
  methods: {
    initData (list, url, num) {
      this.url = url
      this.num = num
      setTimeout(() => {
        let img = document.getElementById("card_img")
        this.wrapWidth = img.clientWidth * 0.3
        this.wrapHieght = img.clientHeight * 0.3
      }, 100);
      this.lists = list
    },
    draggableFun (e) {
      console.log('e', e)
      this.left = e.clientX - 30
      this.top = e.clientY - 30

      this.$set(this.lists[this.activeIndex].points, 'w', 0)
      this.$set(this.lists[this.activeIndex].points, 'h', 0)
      this.active = true

      this.$set(this.lists[this.activeIndex].points, 'x', e.clientX - 30)
      this.$set(this.lists[this.activeIndex].points, 'y', e.clientY - 30)
    },
    mousemove (e) {
      if (this.active) {
        let width = e.clientX - 30 - this.left
        let height = e.clientY - 30 - this.top
        this.$set(this.lists[this.activeIndex].points, 'w', width)
        this.$set(this.lists[this.activeIndex].points, 'h', height)
        this.$forceUpdate()
      }
    },
    up (e) {
      this.active = false
    },
    checkItem (index) {
      this.activeIndex = index
    },
    submit () {
      let bool = false
      var msg = '反面'

      let lists = JSON.parse(JSON.stringify(this.lists)).map((item, index) => {
        item.points.y /= 0.3
        item.points.x /= 0.3
        item.points.w /= 0.3
        item.points.h /= 0.3

        var arr = [0, 1, 2]
        if (this.num == 1) {
          arr.push(3)
          msg = '正面'
        }
        if (arr.includes(index)) {
          if (!item.points.w || !item.points.h) {
            bool = true
          }

        }
        return item
      })
      if (bool) {
        console.log('bool', this.$message.success)
        // this.$message.warning('答题卡' + msg + msg == '正面' ? 'A,B,C,D' : 'A,B,C' + '必填')
        this.$message.warning((msg == '正面' ? 'A,B,C,D' : 'A,B,C') + '必填')
        return
      }
      this.$emit('setData', this.num, '', JSON.stringify(lists))
    },
    toImgStyle (base64Str, fileName) {
      var arr = base64Str.split(','),
        mime = arr[0].match(/:(.*?);/)[1], //base64解析出来的图片类型
        bstr = atob(arr[1]), //对base64串进行操作，去掉url头，并转换为byte   atob为window内置方法
        len = bstr.length,
        u8arr = new Uint8Array(len); //
      while (len--) {
        u8arr[len] = bstr.charCodeAt(len)
      };
      // 创建新的 File 对象实例[utf-8内容，文件名称或者路径，[可选参数，type：文件中的内容mime类型]]
      return new File([u8arr], fileName, {
        type: mime
      })
    },
    add () {
      let obj = {
        label: '',
        points: {
          x: 0,
          y: 0,
          w: 0,
          h: 0
        },
        color: this.rgbToHex()
      }
      this.lists.push(obj)
      this.activeIndex = this.lists.length - 1
    },
    del (index) {
      this.lists.splice(index, 1)
    },
    rgbToHex () {
      return `#${Math.random().toString(16).substr(2, 6)}`;
    }
  }
}
</script>

<style lang="scss" scoped>
.wrap {
  display: flex;
  padding: 30px;
  #do_card_wrap {
    background: grey;
    position: relative;
    background-repeat: no-repeat;
    background-size: 100% 100%;
    border: 2px solid #d9d9d9;
    .line {
      position: absolute;
      background: red;
      opacity: 0.3;
      display: flex;
      justify-content: center;
      align-items: center;
      color: #000000;
      font-size: 16px;
      z-index: 1999;
    }
  }
  .list {
    margin-left: 50px;
    .item {
      margin: 30px 0;
      cursor: pointer;
      span {
        margin-right: 10px;
      }
    }
  }
}
#card_img {
  // 把图片放到页面视图以外的位置，js获取图片宽高
  position: fixed;
  right: 1000000px;
}
.add {
  width: 24px;
  height: 24px;
  margin-bottom: 20px;
  cursor: pointer;
}
</style>